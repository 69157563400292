import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/Layouts/Blog/BlogPost/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`There are many reasons to change your job as a developer: money, needing more freedom, toxic environment, no innovation, and the list could go on and on.`}</p>
    <p>{`Whatever is your reason, you are probably going looking for a new job. It does not matter if you are a junior or a senior developer.`}</p>
    <p>{`How do start looking for a new job?`}</p>
    <p>{`Let’s start talking about 3 basic things that you should think of before starting your job search.`}</p>
    <p><img parentName="p" {...{
        "src": "https://miro.medium.com/max/1400/0*Yuc41iOgqDWg-u9r",
        "alt": "Image"
      }}></img></p>
    <h2>{`WHAT DO YOU REALLY WANT?`}</h2>
    <p>{`That’s what you do: contact the first recruiter that wrote you on LinkedIn and jump on the first opportunity that he will give you. Great idea, right.`}</p>
    <p>{`Don’t do that.`}</p>
    <p>{`The first thing that you should start thinking about is WHY you want a new job and WHAT will be the happy career path for you.`}</p>
    <ul>
      <li parentName="ul">{`What would make you happy in the next job?`}</li>
      <li parentName="ul">{`What would be the perfect environment for you?`}</li>
      <li parentName="ul">{`Which kind of project or industry would you like to work on?`}</li>
    </ul>
    <p><img parentName="p" {...{
        "src": "https://miro.medium.com/max/10000/0*eSp013iK4E_2oZVa",
        "alt": "Image"
      }}></img></p>
    <h2>{`Compensation`}</h2>
    <p>{`After you answered these basic questions, you should ask which offer will make you happy. Money is a big part of your the overall offer but not everything. Have a figure in mind that would make you happy and then evaluate other benefits that you would like in future offers.`}</p>
    <ul>
      <li parentName="ul">{`Are they offering remote working?`}</li>
      <li parentName="ul">{`Flexible working hours?`}</li>
      <li parentName="ul">{`More weeks of holidays?`}</li>
      <li parentName="ul">{`Longer parental leave?`}</li>
      <li parentName="ul">{`Health insurance?`}</li>
    </ul>
    <h2>{`The tech stack`}</h2>
    <p>{`Ok, now you understood what would make you happy in your next job and you have a much clear idea what would be a good offer for you (money and benefits).`}</p>
    <p><img parentName="p" {...{
        "src": "https://miro.medium.com/max/1000/0*umRNu5X17KcO-aqX",
        "alt": "Image"
      }}></img></p>
    <p>{`As a developer, there is the last point that I think it is very important.
Which technologies would you like to use in your next job?`}</p>
    <p>{`For example, If you are planning to work as a frontend developer, would you be ok working with JQuery? Would you only work with ReactJS, Vue or Angular?`}</p>
    <p>{`That’s just an example and you can ask yourself the same kind of questions if you want to be a backend, mobile or full-stack developer.`}</p>
    <p>{`My way to answer this is making a list of technologies I do NOT want to work with. They are technologies I have no interest in learning for different reasons. Every other technology outside this list is worthy and interesting to learn for me, even if I have no experience at all.`}</p>
    <p>{`You decide your next job. You decide your career path.`}</p>
    <p><img parentName="p" {...{
        "src": "https://miro.medium.com/max/2000/0*Je4Nt_2uW_1MGZCk",
        "alt": "Image"
      }}></img></p>
    <p>{`Finding a new job is hard, but finding the right job can be even harder if you don’t think what you really want before you start job hunting.`}</p>
    <p>{`As a recap, if you are looking for a job:`}</p>
    <ul>
      <li parentName="ul">{`Find what would make you happy in your next job.`}</li>
      <li parentName="ul">{`Be clear with yourself about what would be a good offer. Have in mind a base salary and benefits that you will like.`}</li>
      <li parentName="ul">{`Decide which technologies you would like to work on in your next job.`}</li>
    </ul>
    <p>{`Happy job hunting!`}</p>
    <p>{`Well, last thing: If you arrived until here, thank you so much for your support and time that you spend in this page.`}</p>
    <h4>{`FOLLOW ME`}</h4>
    <p>{`Do you know that I have a YouTube channel? `}<a parentName="p" {...{
        "href": "http://bit.ly/YT_DOMENICOSOLAZZO"
      }}>{`Subscribe!`}</a></p>
    <p>{`Where can you find me?`}</p>
    <ul>
      <li parentName="ul">{`Youtube: `}<a parentName="li" {...{
          "href": "http://bit.ly/YT_DOMENICOSOLAZZO"
        }}>{`Domenico Solazzo's Channel`}</a></li>
      <li parentName="ul">{`Instagram: `}<a parentName="li" {...{
          "href": "https://www.instagram.com/domenicosolazzo/"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Linkedin: `}<a parentName="li" {...{
          "href": "https://www.linkedin.com/in/solazzo/"
        }}>{`solazzo`}</a></li>
      <li parentName="ul">{`Medium: `}<a parentName="li" {...{
          "href": "https://medium.com/@domenicosolazzo"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Facebook: `}<a parentName="li" {...{
          "href": "https://www.facebook.com/domenicosolazzo.labs/"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Twitter: `}<a parentName="li" {...{
          "href": "https://twitter.com/domenicosolazzo"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Snapchat: `}<a parentName="li" {...{
          "href": "https://twitter.com/domenicosolazzo"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Github: `}<a parentName="li" {...{
          "href": "https://github.com/domenicosolazzo"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Website: `}<a parentName="li" {...{
          "href": "https://www.domenicosolazzo.com"
        }}>{`https://www.domenicosolazzo.com`}</a></li>
      <li parentName="ul">{`Hashnode: `}<a parentName="li" {...{
          "href": "https://hashnode.com/@domenicosolazzo"
        }}>{`https://hashnode.com/@domenicosolazzo`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      